import { useEffect } from 'react'
import { useAuth } from '@redwoodjs/auth'
import {
  Form,
  FormError,
  FieldError,
  Label,
  TextField,
  Submit,
} from '@redwoodjs/forms'
import { useForm } from 'react-hook-form'
import MDEditor from '@uiw/react-md-editor'

const IdeaForm = (props) => {
  const formMethods = useForm({
    defaultValues: {
      body: props.idea?.body,
    },
  })
  const { currentUser } = useAuth()
  const onSubmit = (data) => {
    if (currentUser.email) {
      props.onSave({ userId: currentUser.email, ...data }, props?.idea?.id)
    }
  }

  useEffect(() => {
    formMethods.register({
      name: 'body',
    })
  }, [])

  const body = formMethods.watch('body')

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={onSubmit} error={props.error} formMethods={formMethods}>
        <FormError
          error={props.error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />

        <Label
          name="title"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Title
        </Label>
        <TextField
          name="title"
          defaultValue={props.idea?.title}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
        />
        <FieldError name="title" className="rw-field-error" />

        <Label
          name="body"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Body
        </Label>
        <MDEditor
          name="body"
          value={body}
          onChange={(value) => {
            formMethods.setValue('body', value)
          }}
        />
        <FieldError name="body" className="rw-field-error" />

        <div className="rw-button-group">
          <Submit disabled={props.loading} className="rw-button rw-button-blue">
            Save
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default IdeaForm

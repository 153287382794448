import Ratings from 'src/components/Ratings'

export const QUERY = gql`
  query RatingsQuery {
    ideas {
      id
      title
      body
      createdAt
      userId
      ratings {
        userId
        easeOfImplementation
        moneyMakingPotential
        passion
        beneficialForEveryone
        beneficialForMe
        marketCompetition
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => <div>Error: {error.message}</div>

export const Success = ({ ideas }) => {
  return <Ratings ideas={ideas} />
}

import cn from 'classnames'

const variants = {
  primary:
    'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded',
  success:
    'bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded',
  error: 'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded',
}
const Button = ({ children, variant = 'primary', className, ...props }) => {
  return (
    <button className={cn(className, variants[variant])} {...props}>
      {children}
    </button>
  )
}

export default Button

import { useAuth } from '@redwoodjs/auth'
import { Link, routes } from '@redwoodjs/router'
import { useState } from 'react'
import { AiFillBulb } from 'react-icons/ai'

const round = (number) => Math.round((number + Number.EPSILON) * 100) / 100

const Ratings = ({ ideas }) => {
  const [sortIndex, setSortIndex] = useState(-1)
  const [viewInTable, setViewInTable] = useState(false)
  const { currentUser } = useAuth()

  const ideasMapped = ideas.reduce(
    (acc, idea) => {
      if (idea.ratings.some((rating) => rating.userId === currentUser.email)) {
        acc.rated = [...acc.rated, idea]
      } else {
        acc.unrated = [...acc.unrated, idea]
      }
      return acc
    },
    {
      unrated: [],
      rated: [],
    }
  )

  const ratedIdeasWithAvg = []
  ideasMapped.rated.forEach((idea) => {
    // Calculate average
    const avgRatings = idea.ratings.reduce(
      (acc, rating, idx) => {
        const keys = Object.keys(rating)
        keys.forEach((key) => {
          acc[key] += rating[key]
          if (idx === idea.ratings.length - 1) {
            acc[key] = acc[key] / idea.ratings.length
          }
        })
        return acc
      },
      {
        easeOfImplementation: 0,
        moneyMakingPotential: 0,
        passion: 0,
        beneficialForEveryone: 0,
        beneficialForMe: 0,
        marketCompetition: 0,
      }
    )
    // Sum total
    avgRatings.total =
      avgRatings.easeOfImplementation +
      avgRatings.moneyMakingPotential +
      avgRatings.passion +
      avgRatings.beneficialForEveryone +
      avgRatings.beneficialForMe +
      avgRatings.marketCompetition
    // Add idea with calculations
    ratedIdeasWithAvg.push({ ...idea, avgRatings })
  })

  const ratedIdeas =
    sortIndex === -1
      ? ratedIdeasWithAvg
      : ratedIdeasWithAvg.sort((a, b) => {
          switch (sortIndex) {
            case 0:
              return (
                b.avgRatings.easeOfImplementation -
                a.avgRatings.easeOfImplementation
              )
            case 1:
              return (
                b.avgRatings.moneyMakingPotential -
                a.avgRatings.moneyMakingPotential
              )
            case 2:
              return b.avgRatings.passion - a.avgRatings.passion
            case 3:
              return (
                b.avgRatings.beneficialForEveryone -
                a.avgRatings.beneficialForEveryone
              )
            case 4:
              return b.avgRatings.beneficialForMe - a.avgRatings.beneficialForMe
            case 5:
              return (
                b.avgRatings.marketCompetition - a.avgRatings.marketCompetition
              )
            case 6:
              return b.avgRatings.total - a.avgRatings.total
            default:
              return 0
          }
        })

  return (
    <div>
      <h2 className="mb-3">Needs rating</h2>

      {ideasMapped.unrated.length > 0 ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
          {ideasMapped.unrated.map((idea) => (
            <IdeaCard idea={idea} key={idea.id} />
          ))}
        </div>
      ) : (
        <div className="text-center mb-16">
          <div className="mb-5">
            You have rated all ideas!{' '}
            <a href="https://www.youtube.com/watch?v=3WAOxKOmR90">NOICE</a>!
          </div>
        </div>
      )}

      <h2 className="mb-3">Rated</h2>

      <div className="hidden md:flex justify-between pb-5">
        <div className="relative z-0 inline-flex shadow-sm -space-x-px">
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              sortIndex === 0 ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-l-md`}
            onClick={() => setSortIndex((curr) => (curr !== 0 ? 0 : -1))}
          >
            Implementation
          </button>
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              sortIndex === 1 ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50`}
            onClick={() => setSortIndex((curr) => (curr !== 1 ? 1 : -1))}
          >
            Money making
          </button>
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              sortIndex === 2 ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50`}
            onClick={() => setSortIndex((curr) => (curr !== 2 ? 2 : -1))}
          >
            Passion
          </button>
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              sortIndex === 3 ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50`}
            onClick={() => setSortIndex((curr) => (curr !== 3 ? 3 : -1))}
          >
            Bene4everyone
          </button>
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              sortIndex === 4 ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50`}
            onClick={() => setSortIndex((curr) => (curr !== 4 ? 4 : -1))}
          >
            Bene4me
          </button>
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              sortIndex === 5 ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-r-md`}
            onClick={() => setSortIndex((curr) => (curr !== 5 ? 5 : -1))}
          >
            Market gap
          </button>
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              sortIndex === 6 ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-r-md`}
            onClick={() => setSortIndex((curr) => (curr !== 6 ? 6 : -1))}
          >
            Total
          </button>
        </div>

        <div className="relative z-0 inline-flex shadow-sm -space-x-px">
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              !viewInTable ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-l-md`}
            onClick={() => setViewInTable(false)}
          >
            Card
          </button>
          <button
            className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
              viewInTable ? 'bg-blue-200' : 'bg-white'
            } text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-r-md`}
            onClick={() => setViewInTable(true)}
          >
            Table
          </button>
        </div>
      </div>

      {viewInTable ? (
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          sortIndex === 0 ? 'bg-blue-200' : 'bg-gray-50'
                        }`}
                      >
                        Ease of implementation avg.
                      </th>
                      <th
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          sortIndex === 1 ? 'bg-blue-200' : 'bg-gray-50'
                        }`}
                      >
                        Money making potential avg.
                      </th>
                      <th
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          sortIndex === 2 ? 'bg-blue-200' : 'bg-gray-50'
                        }`}
                      >
                        Passion avg.
                      </th>
                      <th
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          sortIndex === 3 ? 'bg-blue-200' : 'bg-gray-50'
                        }`}
                      >
                        Beneficial for everyone avg.
                      </th>
                      <th
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          sortIndex === 4 ? 'bg-blue-200' : 'bg-gray-50'
                        }`}
                      >
                        Beneficial for me avg.
                      </th>
                      <th
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          sortIndex === 5 ? 'bg-blue-200' : 'bg-gray-50'
                        }`}
                      >
                        Competition level in the market avg.
                      </th>
                      <th
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                          sortIndex === 6 ? 'bg-blue-200' : 'bg-gray-50'
                        }`}
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {ratedIdeas.map((idea) => (
                      <IdeaCardRow idea={idea} key={idea.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {ratedIdeas.map((idea) => (
            <IdeaCard idea={idea} key={idea.id} showRating />
          ))}
        </div>
      )}
    </div>
  )
}

const IdeaCard = ({ idea, showRating }) => {
  const { currentUser } = useAuth()

  const currentUserRatings =
    idea.ratings.find((rating) => rating.userId === currentUser.email) || {}

  return (
    <Link
      to={routes.rate({ id: idea.id })}
      className="shadow-lg border border-gray-200 rounded p-4 flex mb-5 justify-between flex-col h-full"
    >
      <div>
        <h1 className="text-sm text-bold">
          {idea.title} ({idea.ratings.length} ratings)
        </h1>
      </div>
      {showRating && (
        <div>
          <label className="text-xs text-gray-800 block">
            Ease of implementation avg. (you rated:{' '}
            {currentUserRatings.easeOfImplementation})
          </label>
          <AiFillBulb className="mr-3 inline-block cursor-pointer text-yellow-500 text-xl" />
          {round(idea.avgRatings.easeOfImplementation)}

          <label className="text-xs text-gray-800 block">
            Money making potential avg. (you rated:{' '}
            {currentUserRatings.moneyMakingPotential})
          </label>
          <AiFillBulb className="mr-3 inline-block cursor-pointer text-yellow-500 text-xl" />
          {round(idea.avgRatings.moneyMakingPotential)}

          <label className="text-xs text-gray-800 block">
            Passion avg. (you rated: {currentUserRatings.passion})
          </label>
          <AiFillBulb className="mr-3 inline-block cursor-pointer text-yellow-500 text-xl" />
          {round(idea.avgRatings.passion)}

          <label className="text-xs text-gray-800 block">
            Beneficial for everyone avg. (you rated:{' '}
            {currentUserRatings.beneficialForEveryone})
          </label>
          <AiFillBulb className="mr-3 inline-block cursor-pointer text-yellow-500 text-xl" />
          {round(idea.avgRatings.beneficialForEveryone)}

          <label className="text-xs text-gray-800 block">
            Beneficial for me avg. (you rated:{' '}
            {currentUserRatings.beneficialForMe})
          </label>
          <AiFillBulb className="mr-3 inline-block cursor-pointer text-yellow-500 text-xl" />
          {round(idea.avgRatings.beneficialForMe)}

          <label className="text-xs text-gray-800 block">
            Competition level in the market avg. (you rated:{' '}
            {currentUserRatings.marketCompetition})
          </label>
          <AiFillBulb className="mr-3 inline-block cursor-pointer text-yellow-500 text-xl" />
          {round(idea.avgRatings.marketCompetition)}

          <label className="text-xs text-gray-800 block">Total</label>
          {round(idea.avgRatings.total)}
        </div>
      )}
    </Link>
  )
}

const renderRate = (nr) => {
  const color =
    nr >= 4.5
      ? 'bg-blue-100 text-blue-800'
      : nr >= 3.5
      ? 'bg-green-100 text-green-800'
      : nr >= 2.5
      ? 'bg-yellow-100 text-yellow-800'
      : nr >= 1.5
      ? 'bg-pink-100 text-pink-800'
      : 'bg-red-100 text-red-800'
  const className = `px-4 inline-flex text-xl leading-5 font-semibold rounded-full ${color}`
  return <span className={className}>{nr}</span>
}

const IdeaCardRow = ({ idea }) => (
  <tr>
    <td className="px-6 py-4 whitespace-nowrap">
      <Link to={routes.rate({ id: idea.id })}>{idea.title}</Link>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right">
      {renderRate(round(idea.avgRatings.easeOfImplementation))}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right">
      {renderRate(round(idea.avgRatings.moneyMakingPotential))}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right">
      {renderRate(round(idea.avgRatings.passion))}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right">
      {renderRate(round(idea.avgRatings.beneficialForEveryone))}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right">
      {renderRate(round(idea.avgRatings.beneficialForMe))}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right">
      {renderRate(round(idea.avgRatings.marketCompetition))}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right">
      <span className="px-4 inline-flex text-xl leading-5 font-semibold rounded-full">
        {round(idea.avgRatings.total)}
      </span>
    </td>
  </tr>
)

export default Ratings

import { useMutation, useFlash } from '@redwoodjs/web'
import { Link, routes } from '@redwoodjs/router'

import { QUERY } from 'src/components/IdeasCell'
import { useAuth } from '@redwoodjs/auth'

const DELETE_IDEA_MUTATION = gql`
  mutation DeleteIdeaMutation($id: Int!) {
    deleteIdea(id: $id) {
      id
    }
  }
`

const MAX_STRING_LENGTH = 150

const truncate = (text) => {
  let output = text
  if (text && text.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }
  return output
}

const timeTag = (datetime) => {
  return (
    <time dateTime={datetime} title={datetime}>
      {new Date(datetime).toUTCString()}
    </time>
  )
}

const IdeasList = ({ ideas }) => {
  const { currentUser } = useAuth()
  const { addMessage } = useFlash()
  const [deleteIdea] = useMutation(DELETE_IDEA_MUTATION, {
    onCompleted: () => {
      addMessage('Idea deleted.', { classes: 'rw-flash-success' })
    },
    // This refetches the query on the list page. Read more about other ways to
    // update the cache over here:
    // https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const onDeleteClick = (id) => {
    if (confirm('Are you sure you want to delete idea ' + id + '?')) {
      deleteIdea({ variables: { id } })
    }
  }

  return (
    <div className="rw-segment rw-table-wrapper-responsive">
      <table className="rw-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Title</th>
            <th>Created at</th>
            <th>User id</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {ideas
            .filter(
              (idea) =>
                idea.userId === currentUser.email ||
                currentUser.roles.indexOf('admin') >= 0
            )
            .map((idea) => (
              <tr key={idea.id}>
                <td>{truncate(idea.id)}</td>
                <td>{truncate(idea.title)}</td>
                <td>{timeTag(idea.createdAt)}</td>
                <td>{truncate(idea.userId)}</td>
                <td>
                  <nav className="rw-table-actions">
                    <Link
                      to={routes.idea({ id: idea.id })}
                      title={'Show idea ' + idea.id + ' detail'}
                      className="rw-button rw-button-small"
                    >
                      Show
                    </Link>
                    <Link
                      to={routes.editIdea({ id: idea.id })}
                      title={'Edit idea ' + idea.id}
                      className="rw-button rw-button-small rw-button-blue"
                    >
                      Edit
                    </Link>
                    <a
                      href="#"
                      title={'Delete idea ' + idea.id}
                      className="rw-button rw-button-small rw-button-red"
                      onClick={() => onDeleteClick(idea.id)}
                    >
                      Delete
                    </a>
                  </nav>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default IdeasList

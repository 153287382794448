import { useAuth } from '@redwoodjs/auth'
import { Link, routes } from '@redwoodjs/router'
import Button from 'src/components/Button/Button'
import { RiLogoutBoxRLine } from 'react-icons/ri'

const Header = () => {
  const { logIn, logOut, isAuthenticated, currentUser } = useAuth()
  return (
    <nav className="flex items-center justify-between flex-wrap bg-blue-600 p-6 order-1">
      <div className="flex items-center mb-4 sm:mb-0 flex-shrink-0 text-white sm:mr-8">
        <img
          src="/light-bulb-idea-svgrepo-com.svg"
          width="40px"
          className="mr-3"
        />
        <Link to={routes.home()}>
          <h1 className="font-semibold text-xl tracking-tight none sm:inline-block">
            Idea Ratings
          </h1>
        </Link>
      </div>
      {isAuthenticated && (
        <div className="flex-grow flex items-center w-full sm:w-auto order-3 sm:order-2 text-sm">
          <Link
            to={routes.ratings()}
            className="text-lg inline-block mt-0 text-teal-200 hover:text-white mr-6"
          >
            Ratings
          </Link>
          <Link
            to={routes.ideas()}
            className="text-lg inline-block mt-0 text-teal-200 hover:text-white mr-6"
          >
            Ideas
          </Link>
          {currentUser?.roles?.indexOf('admin') >= 0 && (
            <Link
              to={routes.users()}
              className="text-lg inline-block mt-0 text-teal-200 hover:text-white"
            >
              Users
            </Link>
          )}
        </div>
      )}
      <div className="items-end order-2 sm:order-3 mb-4 sm:mb-0">
        {isAuthenticated && (
          <span
            className="inline-block mt-0 text-teal-200 mr-4"
            title={currentUser.email}
          >
            {currentUser?.user_metadata?.full_name}
          </span>
        )}
        {isAuthenticated ? (
          <Button onClick={logOut}>
            <RiLogoutBoxRLine />
          </Button>
        ) : (
          <Button onClick={logIn}>Log in</Button>
        )}
      </div>
    </nav>
  )
}

export default Header

import Idea from 'src/components/Idea'

export const QUERY = gql`
  query FIND_IDEA_BY_ID($id: Int!) {
    idea: idea(id: $id) {
      id
      title
      body
      createdAt
      userId
      ratings {
        userId
        easeOfImplementation
        moneyMakingPotential
        passion
        beneficialForEveryone
        beneficialForMe
        marketCompetition
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Idea not found</div>

export const Success = ({ idea, successCmp: Cmp }) => {
  return Cmp ? <Cmp idea={idea} /> : <Idea idea={idea} />
}

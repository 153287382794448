import MDEditor from '@uiw/react-md-editor'
import { useMutation, useFlash } from '@redwoodjs/web'
import { Link, routes, navigate } from '@redwoodjs/router'

import { QUERY } from 'src/components/IdeasCell'

const DELETE_IDEA_MUTATION = gql`
  mutation DeleteIdeaMutation($id: Int!) {
    deleteIdea(id: $id) {
      id
    }
  }
`

const timeTag = (datetime) => {
  return (
    <time dateTime={datetime} title={datetime}>
      {new Date(datetime).toUTCString()}
    </time>
  )
}

const Idea = ({ idea }) => {
  const { addMessage } = useFlash()
  const [deleteIdea] = useMutation(DELETE_IDEA_MUTATION, {
    onCompleted: () => {
      navigate(routes.ideas())
      addMessage('Idea deleted.', { classes: 'rw-flash-success' })
    },
    // This refetches the query on the list page. Read more about other ways to
    // update the cache over here:
    // https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const onDeleteClick = (id) => {
    if (confirm('Are you sure you want to delete idea ' + id + '?')) {
      deleteIdea({ variables: { id } })
    }
  }
  const avg = idea.ratings.reduce(
    (acc, rating, idx) => {
      const keys = Object.keys(rating)
      keys.forEach((key) => {
        acc[key] += rating[key]
        if (idx === idea.ratings.length - 1) {
          acc[key] = acc[key] / idea.ratings.length
        }
      })
      return acc
    },
    {
      easeOfImplementation: 0,
      moneyMakingPotential: 0,
      passion: 0,
      beneficialForEveryone: 0,
      beneficialForMe: 0,
      marketCompetition: 0,
    }
  )

  console.table(idea.ratings)
  return (
    <>
      <div className="rw-segment">
        <header className="rw-segment-header">
          <h2 className="rw-heading rw-heading-secondary">
            Idea {idea.id} Detail
          </h2>
        </header>
        <table className="rw-table">
          <tbody>
            <tr>
              <th>Id</th>
              <td>{idea.id}</td>
            </tr>
            <tr>
              <th>Title</th>
              <td>{idea.title}</td>
            </tr>
            <tr>
              <th>Body</th>
              <td>
                <MDEditor.Markdown source={idea.body} />
              </td>
            </tr>
            <tr>
              <th>Created at</th>
              <td>{timeTag(idea.createdAt)}</td>
            </tr>
            <tr>
              <th>User id</th>
              <td>{idea.userId}</td>
            </tr>
            <tr>
              <th>Ease of implementation avg.</th>
              <td>{avg.easeOfImplementation}</td>
            </tr>
            <tr>
              <th>Money making potential avg.</th>
              <td>{avg.moneyMakingPotential}</td>
            </tr>
            <tr>
              <th>Passion avg.</th>
              <td>{avg.passion}</td>
            </tr>
            <tr>
              <th>Beneficial for everyone avg.</th>
              <td>{avg.beneficialForEveryone}</td>
            </tr>
            <tr>
              <th>Beneficial for me avg.</th>
              <td>{avg.beneficialForMe}</td>
            </tr>
            <tr>
              <th>Competition level in the market avg.</th>
              <td>{avg.marketCompetition}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav className="rw-button-group">
        <Link
          to={routes.editIdea({ id: idea.id })}
          className="rw-button rw-button-blue"
        >
          Edit
        </Link>
        <a
          href="#"
          className="rw-button rw-button-red"
          onClick={() => onDeleteClick(idea.id)}
        >
          Delete
        </a>
      </nav>
    </>
  )
}

export default Idea

import Header from 'src/components/Header'
import { Flash } from '@redwoodjs/web'

const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="container mx-auto pt-4">
        <Flash timeout={3500} />
      </div>
      <main className="container mx-auto px-4 py-8">{children}</main>
    </>
  )
}

export default MainLayout
